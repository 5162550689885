import React from "react";
import { StyledCallFor, StyledCallForItem, StyledCallForItems } from "./style";
import waveSrc from "../../../static/2020/images/impactcall/wave-call-for.png";
import waveDesktopSrc from "../../../static/2020/images/impactcall/wave-call-for-desktop.png";
import waveBottomSrc from "../../../static/2020/images/impactcall/wave-call-for-bottom.png";
import waveBottomDesktopSrc from "../../../static/2020/images/impactcall/wave-call-for-bottom-desktop.png";
import mobile0Src from "../../../static/2020/images/impactcall/call-for-mobile-0.png";
import mobile1Src from "../../../static/2020/images/impactcall/call-for-mobile-1.png";
import mobile2Src from "../../../static/2020/images/impactcall/call-for-mobile-2.png";
import desktopSrc from "../../../static/2020/images/impactcall/call-for-space.png";

export const CallFor = () => (
  <>
    <img src={waveSrc} className="mobileOnly" loading="lazy" />
    <img src={waveDesktopSrc} className="desktopOnly" loading="lazy" />
    <StyledCallFor id="contest">
      <h2>
        CALL FOR IMPACTABILITY
        <br />
        2°Edizione<span> - </span>Milano 2022
      </h2>
      <p>
        Per partecipare alla Call for Impactability non importa chi sei e da dove vieni.
        Basta avere più di 18 anni e presentare un programma elaborato in gruppo o singolarmente che presenti
        questo unico requisito: la capacità di generare un impatto positivo e concretamente trasformativo
        nella società in cui viviamo. Candidati entro il 30 aprile!
      </p>
      <strong>
        Il tuo progetto deve rientrare nella seguente categoria:
      </strong>
      <img src={desktopSrc} className="desktopOnly" loading="lazy" />
      <StyledCallForItems>
        <StyledCallForItem>
          <img src={mobile0Src} className="mobileOnly" loading="lazy" />
          <h3>Impatto tramite azioni concertate dalle comunità</h3>
          <p>
            L’unione fa davvero la forza. La collettività nel suo insieme può davvero farsi
            attrice attiva del cambiamento e motore di un miglioramento generale.
            I progetti da presentare si concentrano sull’idea di impatto che viene dalle comunità in favore delle comunità.
          </p>
        </StyledCallForItem>
        <StyledCallForItem>
          <img src={mobile0Src} className="mobileOnly" loading="lazy" />
          <h3>Impatto tramite azioni tecnologiche</h3>
          <p>
            La tecnologia può essere un alleato fortissimo per creare impatto. Se hai un progetto o un idea che sfrutta
            l'ingegno e la tecnologia questa è la call che fa per te. Assieme potremo crescerla e finanziarla.
          </p>
        </StyledCallForItem>
      </StyledCallForItems>
    </StyledCallFor>
    <img src={waveBottomSrc} className="mobileOnly" loading="lazy" />
    <img src={waveBottomDesktopSrc} className="desktopOnly" loading="lazy" />
  </>
);
